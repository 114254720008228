/**
 * @ignore
 */
export function identity(x: any) {
  return x;
}

/**
 * @ignore
 */
export async function identityAsync(x: any) {
  return x;
}
